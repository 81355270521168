import { useNavigate } from "react-router-dom";
import { Page } from "App";
import Button from "components/Button";
import { setTitle } from "helpers/title";
import { useEffect } from "react";

import logo from 'assets/logo512.png';

function Home({ pages }: { pages: Page[] }) {
  const navigate = useNavigate();

  useEffect(() => {
    setTitle('Home');
  }, []);

  return (
    <div className="text-center overflow-hidden bg-primary">
      <header className="h-screen flex flex-col items-center justify-center gap-y-10 p-10">
        <div className="max-h-[512px] flex-shrink flex-1 aspect-square bg-contain" style={{
          backgroundImage: `url(${logo})`,
          boxShadow: '0 0 15px 15px #282c34 inset',
        }} />
        <div className="flex flex-row content-center flex-wrap gap-5 items-center justify-center text-sd text-white">
          {pages.map((page, index) => (
            <Button key={index} onClick={() => navigate(page.path)}>{page.name}</Button>
          ))}
        </div>
      </header>
    </div>
  );
}

export default Home;