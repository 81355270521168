import { Route, Routes } from 'react-router-dom';

// Import Pages
import Home from 'pages/Home';
import Map from 'pages/Map';
import Wiki from 'pages/Wiki';
import Discord from 'pages/Discord';

// Import Styles
import './App.css';

export const title = 'Lunaria';

export type Page = {
  name: string;
  path: string;
  element: JSX.Element;
};

function App() {
  const pages: Page[] = [
    {
      name: 'Map',
      path: '/map/',
      element: <Map />
    },
    {
      name: 'Wiki',
      path: '/wiki/',
      element: <Wiki />
    },
    {
      name: 'Discord',
      path: '/discord/',
      element: <Discord />
    }
  ];

  return (
    <Routes>
      <Route path="/" element={<Home pages={pages} />} />
      {pages.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default App;
