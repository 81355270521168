import { title } from "App";

function generateTitle(pageTitle: string): string {
  return `${title} | ${pageTitle}`;
}

function setTitle(pageTitle: string): void {
  document.title = generateTitle(pageTitle);
}

export { setTitle };