import { setTitle } from "helpers/title";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Map() {
  const navigate = useNavigate();

  useEffect(() => {
    setTitle('Map');
  }, []);

  return (
    <div className="text-center overflow-hidden">
      <header className="bg-primary min-h-screen flex flex-row gap-5 items-center justify-center text-sd text-white">
        <iframe title='Map' src='https://lunaria.mc.thaleius.de/map/' className='w-screen h-screen'></iframe>
        <button className='absolute bottom-0 left-0 bg-primary px-2 py-1 rounded-tr text-red-500' onClick={() => navigate(-1)}>Close</button>
      </header>
    </div>
  )
}

export default Map;