import { useNavigate } from "react-router-dom";
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid';

export type Positions = 'tl' | 'tr' | 'bl' | 'br';

function Back({ position, className }: { position: Positions, className?: string }) {
  const navigate = useNavigate();

  const positions = {
    tl: 'top-0 left-0',
    tr: 'top-0 right-0',
    bl: 'bottom-0 left-0',
    br: 'bottom-0 right-0'
  };

  return (
    <button onClick={() => navigate(-1)} className={`absolute ${positions[position]} bg-primary px-2 py-1 rounded-tr text-white${className ? ' ' + className : ''}`}><ArrowLongLeftIcon className="size-16" /></button>
  );
}

export default Back;