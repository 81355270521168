import Back from "components/Back";
import { setTitle } from "helpers/title";
import { useEffect } from "react";

function Discord() {
  useEffect(() => {
    setTitle('Discord');
  });

  return (
    <div className="text-center overflow-hidden">
      <header className="bg-primary min-h-screen flex flex-row gap-5 items-center justify-center text-sd text-white">
        <p>
          Unser Discord-Server ist noch in Arbeit. Bitte hab noch etwas Geduld.
        </p>
      </header>
      <Back position='tl' />
    </div>
  );
}

export default Discord;