type ButtonProps = {
  onClick?: () => void;
  children: string;
};

function Button(props: ButtonProps) {

  return <button onClick={props.onClick} className="p-4 border rounded-lg w-60 h-44 hover:shadow-lg transition-all shadow-white hover:text-black hover:bg-opacity-75 hover:bg-secondary">{props.children}</button>;
}

export default Button;