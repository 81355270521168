import Back from "components/Back";
import { setTitle } from "helpers/title";
import { useEffect } from "react";

function Wiki() {
  useEffect(() => {
    setTitle('Wiki');
  }, []);

  return (
    <div className='bg-primary min-h-screen flex flex-col gap-5 items-center justify-center text-white'>
      <h1 className='text-3xl'>Wiki</h1>
      <p className="text-sd">
        Willkommen im Wiki! Hier findest du alle Informationen, die du für das Spielen auf Lunaria benötigst.
      </p>
      <p>
        Inhalte folgen in Kürze.
      </p>
      <Back position='tl' />
    </div>
  );
}

export default Wiki;